.home {
  padding: 20px;
}
.home__container {
  display: flex;
}
.home__left {
  margin: 10px;

  border-right: 1px solid lightslategray;
}
.home__right {
  width: 100%;
  margin: 10px;
}
