.dashboard {
  padding: 10px;
}
.dashboard_activePlan {
  border: 1px solid #123;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard_NoactivePlan {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;

  flex-direction: column;
  align-items: center;
}
.dashboard_NoactivePlan > button {
  margin-top: 10px;
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
.dashboard__freetrial {
  margin-top: 40px;
  background-color: orange;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard__freetrialDescription {
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboard__freetrial > button {
  margin-top: 10px;
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  width: 200px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
.dashboard__help {
  margin-top: 30px;
  margin-bottom: 40px;
}
