.manageDevice {
  display: flex;
  border: 1px solid #656555;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 10px;
  justify-content: space-between;
  padding-right: 10px;
  height: 70px;
  margin-top: 20px;
  align-items: center;
  width: 500px;
}
.manageDevice__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manageDevice__body > p {
  height: 30px;
  background-color: lightgreen;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  font-weight: 500;
  color: white;
  border-radius: 5px;
}

.manageDevice__body > div {
  cursor: pointer;
  height: 30px;
  align-items: center;
  background-color: white;
  border-radius: 3px;
  border: 1px solid red;
  color: red;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
.manageDevice__body > div:hover {
  background-color: red;
  border: 1px solid red;
  color: white;
  font-weight: 500;
}
.manageDevice__ip {
  display: flex;
}
