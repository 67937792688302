.checkout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  background-color: #f7f5f0;
}
.checkout__title {
  margin-bottom: 55px;
}
.checkout__container {
  width: 500px;
  background-color: white;
  border-radius: 5px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 1px 5px 11px 10px #cecac3;
}
.checkout__body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
}
.checkout__validity,
.checkout__planName,
.checkout__amount {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
.checkout__button {
  display: flex;
  justify-content: center;
}
.checkout__button > button {
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
