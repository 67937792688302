.apiDocumentation {
  padding-top: 20px;
  padding-left: 20px;
  width: 100%;
}
.apiDocumentation__title {
  margin-bottom: 20px;
}

.apiDocumentation__description {
  margin-bottom: 20px;
}

.apiDocumentation__key {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.apiDocumentation form {
  display: flex;
  padding-top: 10px;
  background-color: #dfe8e1;
  margin-top: 10px;
  padding-left: 5px;
  border-radius: 8px;
  padding-right: 1px;
}
.apiDocumentation form > textarea {
  text-decoration: none;
  decoration: none;
  width: 100%;
  border: none;
  height: 40px;
  resize: none;
  background-color: transparent;
}
.apiDocumentation form > textarea:focus {
  border: none;
  text-decoration: none;
}
