.footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 10px;
}
.footer__copyright > p {
  font-size: 10px;
  margin-left: 10px;
}
.footer__terms {
  display: flex;
  margin-bottom: 5px;
}
.footer__terms > div {
  margin-left: 10px;
  font-size: 10px;
}
