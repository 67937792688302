.manage {
  padding-left: 10px;
  width: 100%;
}
.manage__container > h4 {
  color: lightslategrey;
}
.manage__ip {
  display: flex;
  border: 1px solid #656555;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 10px;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 20px;
  align-items: center;
  width: 500px;
}
.manage__adip {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
