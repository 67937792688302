.planItem {
  margin-top: 10px;
  border: 1px solid lightblue;
  padding: 10px;
  border-radius: 4px;
  width: 280px;
  margin-right: 10px;
}

.planItem__name {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
}
.planItem__name > div {
  border: 1px solid orange;
  padding-top: 0px;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 15px;
  background-color: orange;
  color: white;
}
.planItem__description {
  margin-top: 20px;
  padding: 4px;
  text-align: center;
}
.planItem__price {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: 10px;
}

.planItem__validity {
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.planItem__select {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.planItem__select > button {
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
