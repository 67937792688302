.qrscan {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}
.qrscan > img {
  margin-top: 5px;
  top: 0px;
  left: -300px;
  position: relative;
  clip-path: inset(180px 150px 330px 700px);
}
