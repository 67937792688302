.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #f5f5f5;
}
.login__container {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
@media (max-width: 400px) {
  .login__container {
    width: 90%;
  }
}

.login__logo {
  display: flex;
  align-items: center;
}
.login__logo > img {
}
.login__title {
  margin-top: 10px;
  margin-bottom: 15px;
}
.login__form {
  width: 100%;
  justify-content: center;
}
.login__form > form > input {
  width: 98%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  border: 2px solid #a3903b;
  border-radius: 4px;
  height: 30px;
}

.login__signInContainer {
  display: flex;
  justify-content: center;
}
.login__signInButton {
  margin-top: 20px;
  width: 75%;
  height: 35px;
  background-color: #a3903b;
  border-color: #a3903b #a3903b #a3903b;
  border-radius: 5px;
  color: #111;
  outline: none;
  font-weight: bolder;
  font-size: 18px;
}
.login__signInButton:active {
  border-color: #a3903b #a3903b #a3903b;

  transform: translateY(2px);
}
.login__error {
  display: flex;
  object-fit: contain;
  margin-bottom: 5px;
}
.login__error > p {
  color: red;
  font-weight: 500;
}
.login__forgotPassword {
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: #a3903b;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.login__forgotPassword > p {
  cursor: pointer;
}
.login__register {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.login__registerText {
  color: #a3903b;
}
