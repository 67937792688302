.sidemenu {
  margin-right: 20px;
}
.sidemenu__itemActive {
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  border-radius: 4px;
  background-color: rgb(26, 37, 37);
}

.sidemenu__itemDisabled {
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
