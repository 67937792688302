.paymenthistory {
  padding: 20px;
}
.paymenhistory__title {
  margin-bottom: 20px;
}
.paymenthistory__data {
  display: flex;
  justify-content: center;
}
.paymenthistory__data > table {
  border-collapse: collapse;
  width: 85%;
}
th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: rgb(26, 37, 37);
  color: white;
}
.success {
  width: 100px;
  background-color: #4caf50;
  text-align: center;
  border-radius: 4px;
  color: white;
}
.failed {
  width: 100px;

  background-color: #f30909;
  text-align: center;
  border-radius: 4px;
  color: white;
}
