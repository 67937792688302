.terms {
  padding: 20px;
}
.terms__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.terms__header > p {
  padding: 20px;
  text-align: center;
}
.terms__body {
  border: 1px solid grey;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.terms__body > p {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}
