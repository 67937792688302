.header {
  height: 60px;
  display: flex;
  width: 100%;
  background-color: rgb(26, 37, 37);
  padding-left: 10px;
}
.header__logo {
  padding-top: 5px;
  display: flex;
  width: 400px;
  align-items: center;
}
.header__logoImg {
  height: 50px;
}
.header__logo > h3 {
  color: white;
  width: 100%;
}
.header__userInfo {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
}
.header__userName {
  cursor: pointer;
  color: white;
  font-weight: 460;
}
.header__userDropDown {
  display: none;
}
.header__userDropDown {
  display: flex;
  margin-top: 150px;
  flex-direction: column;
  position: absolute;
  min-width: 200px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  margin-right: 0px;
  z-index: 1;
  color: black;
  border-radius: 10px;
}
.header__userDropDown > p {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.header__expandIcon {
  color: white;
  cursor: pointer;
}
.header__dropDownItem {
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: white;
}
.header__dropDownItem > p {
  padding-left: 10px;
  text-decoration: underline;
}
.header__dropDownItem:hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
