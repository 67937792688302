.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #f5f5f5;
}
.register__container {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
@media (max-width: 400px) {
  .register__container {
    width: 90%;
  }
}

.register__logo {
  display: flex;
  align-items: center;
}
.register__logo > img {
  height: 30px;
}
.register__title {
  margin-top: 10px;
  margin-bottom: 15px;
}
.register__form {
  width: 100%;
  justify-content: center;
}
.register__form > form > input {
  width: 98%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  border: 2px solid #a3903b;
  border-radius: 4px;
  height: 30px;
}

.register__signInContainer {
  display: flex;
  justify-content: center;
}
.register__signInButton {
  margin-top: 20px;
  width: 75%;
  height: 35px;
  background-color: #a3903b;
  border-color: #a3903b #a3903b #a3903b;
  border-radius: 5px;
  color: #111;
  outline: none;
  font-weight: bolder;
  font-size: 18px;
}
.register__signInButton:active {
  border-color: #a3903b #a3903b #a3903b;

  transform: translateY(2px);
}
.register__error {
  display: flex;
  object-fit: contain;
  margin-bottom: 5px;
}
.register__error > p {
  color: red;
  font-weight: 500;
}
.register__forgotPassword {
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: #a3903b;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.register__forgotPassword > p {
  cursor: pointer;
}
.register__register {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.register__registerText {
  color: #a3903b;
}
