.addwhatsapp {
  padding: 5px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 80px;
}
.addwhatsapp__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.addwhatsapp__connect button {
  background-color: rgb(26, 37, 37);
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(26, 37, 37) rgb(27, 36, 39) rgb(24, 35, 38);
  padding-right: 15px;
  color: white;
}
